import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { PageHeaderHalfHeight } from 'modules/contentBlocks';
import { ConceptCarousel } from 'modules/StudiosPage';
import { Template } from 'common/layout';
import { ContentArea } from 'common/contentarea';

const StudioDetail: React.FC<StudioPageProps> = ({ data }) => {
  const studio = data?.contentfulTypeStudio;
  const conceptsActiveAt = data?.contentfulTypeStudio?.conceptsActiveAt;
  const body = studio?.detailPageBody as i.ContentAreaBlocksTypes[];

  return (
    <Template>
      <PageHeaderHalfHeight
        content={{
          title: studio?.name || '',
          backgroundMedia: studio?.frontImage || '',
        }}
      />
      <ContentArea contentTypes={body} />
      {conceptsActiveAt?.length !== 0 && (
        <ConceptCarousel concepts={conceptsActiveAt} />
      )}
    </Template>
  );
};

export default StudioDetail;

export const query = graphql`
  query StudioPage($id: String, $language: String) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
    contentfulTypeStudio(id: {eq: $id}, node_locale: {eq: $language}) {
      conceptsActiveAt {
        slug
        shortDescription
        title
        frontImage {
          file {
            url
          }
        }
      }
      frontImage {
        file {
          url
        }
      }
      name
      detailPageBody {
        __typename
        ...PageBodyFragment
      }
    }
  }
`;

type StudioPageProps = {
  data: GatsbyTypes.StudioPageQuery;
};
