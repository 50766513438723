import styled from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';

import { Text } from 'common/typography';

export const CardContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.colors.gray.light};
  height: 360px;
  border-radius: 4px 0;
  width: 264px;
  cursor: pointer;
`;

export const ImageHeader = styled.img`
  border-radius: 4px 4px 0;
  width: 100%;
  height: 100%;
  max-width: 264px;
  max-height: 160px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  height: 100%;
`;

export const CardHeading = styled(Text)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const AddressColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ContactInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  gap: 4px;
`;

export const ContactInfoRow = styled.div<ContactInfoRowProps>`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ $flexGap }) => $flexGap || '0px'};
`;

type ContactInfoRowProps = {
  $flexGap?: string;
};
