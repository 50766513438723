import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 760px;

  ${media.desktop`
    display: grid;
    grid-template-columns: 45% auto;
  `}
`;

export const StudiosContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 20px 24px;

  ${media.desktop`
    padding: 80px 122px;
    align-items: stretch;
  `}
`;

export const StudiosList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    max-width: 606px
  `}
`;

export const MapContainer = styled.img`
  display: none;

  ${media.desktop`
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  `}
`;
