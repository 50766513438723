import * as i from 'types';
import * as React from 'react';

import { Carousel } from 'common/interaction';

export const ConceptCarousel: React.FC<ConceptCarouselProps> = ({ concepts }) => {
  if (!concepts) return null;

  const slides = concepts.map((concept) => ({
    ...concept,
    title: concept.title,
    tagline: concept.shortDescription,
    cta: `/concepts/${concept.slug}`,
  }));

  return (
    <Carousel slides={slides} />
  );
};

type ConceptCarouselProps = {
  concepts: {
    frontImage: i.MediaType;
    title: string;
    slug: string;
    shortDescription: string;
  }[];
};
