import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useSelector } from 'services/hooks';
import { selectActiveSiteData } from 'selectors/sites';
import ClubOverviewImage from 'images/studios.jpg';

import { SiteSelector } from 'common/interaction';
import { HalfHeader } from 'common/layout';

export const StudiosHeader = () => {
  const { t } = useTranslation();
  const activeSite = useSelector(selectActiveSiteData);

  return (
    <HalfHeader
      title={t('discover')}
      subtitle={t('our studios of')}
      imageUrl={ClubOverviewImage}
      value={activeSite}
      marginBottom={0}
    >
      <SiteSelector label={t('studios of')} />
    </HalfHeader>
  );
};
